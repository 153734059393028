import { t } from '@zupr/i18n'
import React from 'react'

import ExternalLink from '../../router/link-external'

interface SupportProps {
    url: string
    className?: string
    children?: React.ReactNode
}

const Support = ({ url, className, children }: SupportProps) => (
    <ExternalLink
        className={className}
        href={`https://support.beezy.nl/${url}`}
    >
        {children || t('more info')}
    </ExternalLink>
)

export default Support
